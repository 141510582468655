import WKT from "ol/format/WKT";
const GeomToString = (geom) => {
  const format = new WKT();
  return format.writeGeometry(geom);
};
const StringToGeom = (geom_st) => {
  try {
    const format = new WKT();
    return format.readGeometry(geom_st);
  } catch (e) {
    return false;
  }
};
const WKTFormatter = {
  GeomToString,
  StringToGeom,
};

export default WKTFormatter;
