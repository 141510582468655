import React, { useContext, useEffect, useState } from "react";
import MapContext from "../../../Contexts/MapContext.js";
import eventEmitter from "../../../Shared/events";
//OL
import Draw from "ol/interaction/Draw";
import { Circle, Fill, Stroke, Style } from "ol/style";
import { Polygon, LineString, MultiLineString } from "ol/geom";
import Overlay from "ol/Overlay";
import RichLogger from "../../../Shared/richlogger";
import Confirm from "../../Confirm";
import "./index.scss";
const logger = new RichLogger("components/Tools/AddPolygon");
let continuePolygonMsg = "Click to continue drawing the polygon"; //Message to show when a user is drawing a polygon
let continueLineMsg = "Click to continue drawing the line"; //Message to show when the user is drawing a line.
let helpMsg = "Click to start drawing";
let helpTooltipElement = null;
let helpTooltip = null;
let drawStartEvent = null;
let drawEndEvent = null;
let sketch = null;
let draw = null;

const AddPolygon = (props) => {
  const { map, setAddedGeom } = useContext(MapContext);
  const [render, setRender] = useState(false);
  const { geom_fill_color, geom_stroke_color } = props.customGeoms;
  const drawStyle = new Style({
    fill: new Fill({
      color: geom_fill_color,
    }),
    stroke: new Stroke({
      color: geom_stroke_color,
      lineDash: [10, 10],
      width: 2,
    }),
    image: new Circle({
      radius: 4,
      fill: new Fill({
        color: geom_fill_color,
      }),
      stroke: new Stroke({
        color: geom_stroke_color,
        width: 2,
      }),
    }),
  });
  const addInteraction = (type) => {
    logger.log("addInteraction()", type);
    draw = new Draw({
      source: props.vectorLayerSource,
      type: type,
      style: drawStyle,
    });

    map.addInteraction(draw);

    createHelpTooltip();

    drawStartEvent = draw.on(
      "drawstart",
      function (evt) {
        logger.log("drawstart");
        // set sketch
        sketch = evt.feature;
      },
      this
    );

    drawEndEvent = draw.on(
      "drawend",
      function (evt) {
        logger.log("drawend");
        setAddedGeom(sketch.getGeometry());
        if (draw) {
          map.removeInteraction(draw);
          draw = null;
        }
        removeToolTip();
      },
      this
    );
  };

  const removeToolTip = () => {
    if (helpTooltipElement) {
      helpTooltipElement.parentNode.removeChild(helpTooltipElement);
      helpTooltipElement = null;
    }
  };
  const createHelpTooltip = () => {
    logger.log("createHelpTooltip");
    if (helpTooltipElement) {
      helpTooltipElement.parentNode.removeChild(helpTooltipElement);
    }
    helpTooltipElement = document.createElement("div");
    helpTooltipElement.className = "tooltipbase";
    helpTooltip = new Overlay({
      element: helpTooltipElement,
      offset: [15, 0],
      positioning: "center-left",
    });
    map.addOverlay(helpTooltip);
  };

  const pointerMoveHandler = (evt) => {
    if (evt.dragging) {
      return;
    }
    var tooltipCoord = evt.coordinate;
    if (sketch) {
      var output;
      var geom = sketch.getGeometry();
      if (geom instanceof Polygon) {
        helpMsg = continuePolygonMsg;
        tooltipCoord = geom.getInteriorPoint().getCoordinates();
      } else if (
        geom instanceof LineString ||
        geom instanceof MultiLineString
      ) {
        helpMsg = continueLineMsg;
        tooltipCoord = geom.getLastCoordinate();
      }
    }
    if (helpTooltipElement) {
      helpTooltipElement.innerHTML = helpMsg;
      helpTooltip.setPosition(evt.coordinate);
    }
  };
  //*****************************************************************/
  //*******************           EFFECTS          ******************/
  //*****************************************************************/
  useEffect(() => {
    logger.log("AddPolygon tool rendered", props.tool);
    eventEmitter.on("pointermove", (evt) => {
      pointerMoveHandler(evt);
    });
    if (props.tool === "add_Polygon") {
      addInteraction("Polygon");
    } else if (props.tool === "add_Line") {
      addInteraction("MultiLineString");
    }

    return () => {
      //remove event listeners
      eventEmitter.removeAllListeners();
      //remove interactions
      if (draw) {
        map.removeInteraction(draw);
        draw = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!map) return;
    setRender(true);
  }, [map]);

  useEffect(() => {
    if (!render) {
    }
  }, [render]);

  //*****************************************************************/
  //*******************        END EFFECTS         ******************/
  //*****************************************************************/

  //*****************************************************************/
  //*******************           BUTTONS          ******************/
  //*****************************************************************/

  const cancelAdd = () => {
    logger.log("cancelAdd()");
    props.cancelAction();
    setAddedGeom("cancelAdd");
    setRender(false);
    removeToolTip();
  };

  //*****************************************************************/
  //*******************          END BUTTONS       ******************/
  //*****************************************************************/

  return (
    <React.Fragment>
      <Confirm cancelAdd={cancelAdd}></Confirm>
    </React.Fragment>
  );
};
export default AddPolygon;

/*



  if(!isDrawing){
    resetAddTools();
    addInteraction(type);
    isDrawing	= true;
    $rootScope.$broadcast('addingPoint',{adding:true,type:type});
}



	function addInteraction(type) {
		log('addInteraction()',type);
		draw 		= new ol.interaction.Draw({
						source: vectorSource,
						type:  (type),
						style: drawStyle
					});

		map.addInteraction(draw);

		createHelpTooltip();

		drawStartEvent	= draw.on('drawstart',function(evt) {
			log('drawstart')
					// set sketch
					sketch = evt.feature;
			}, this);

			drawEndEvent = draw.on('drawend',function(evt) {
				log('drawend');
				notifyGeometry(sketch.getGeometry());
			if(draw){
				map.removeInteraction(draw);
				draw		= null;
			}
		}, this);
	}

	function forceEndDrawing(){
		log('forceEndDrawing');
		console.log(sketch)
		notifyGeometry(sketch.getGeometry());
		if(draw){
			map.removeInteraction(draw);
			draw		= null;
		}
	}
	//returns line/polygon geometry and reset tools
	function fixGeometry(){
		log('fixGeometry()');
		if(sketch){
			var geometry	= sketch.getGeometry();
			//unset sketch
			sketch = null;
			resetAddTools();
			return geometry;
		}
	}


  	function pointerMoveHandler(evt) {
		//log("pointerMoveHandler",evt);
		if (evt.dragging) {
				return;
			}

			var tooltipCoord 	= evt.coordinate;
			if (sketch) {
				var output;
			var geom 		= (sketch.getGeometry());
			if (geom instanceof ol.geom.Polygon) {
				helpMsg 		= continuePolygonMsg;
				tooltipCoord 	= geom.getInteriorPoint().getCoordinates();
				} else if (geom instanceof ol.geom.LineString || geom instanceof ol.geom.MultiLineString) {

				helpMsg 		= continueLineMsg;
				tooltipCoord 	= geom.getLastCoordinate();
			}
			}
			if (helpTooltipElement) {
			helpTooltipElement.innerHTML = helpMsg;
			helpTooltip.setPosition(evt.coordinate);
		}
	};
*/
