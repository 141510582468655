import React, { Component } from "react";
import RichLogger from "../../Shared/richlogger";
import withIframeParams from "../../hoc/withIframeParams";
import VidroMap from "../../Components/VidroMap";
import { MapProvider } from "../../Contexts/MapContext.js";

//style
import "./index.scss";

const logger = new RichLogger("pages->home");

class Home extends Component {
  constructor(props) {
    super(props);
    logger.log("constructor", this.props);
  }
  componentDidMount() {}
  componentDidUpdate() {}

  render() {
    return (
      <MapProvider>
        <VidroMap iframeParams={this.props.iframeParams}></VidroMap>
      </MapProvider>
    );
  }
}

export default withIframeParams(Home);
