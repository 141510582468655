import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//import { saveAuthToken, logs, debug } from "./middlewares";
import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["debug", "auth", "room"], // only persist these keys
  version: 1, //must be updated when reducer's structure change
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(
  persistedReducer
  // composeEnhancers(applyMiddleware(saveAuthToken, logs, debug))
);

let persistor = persistStore(store);

export { store, persistor };
