import axios from "axios";
import RichLogger from "../richlogger";
const vidromapIsPublished = (props) => {
  const logger = new RichLogger("vidromaps-service");
  return new Promise((resolve) => {
    let requestUrl = `${props.api_uri}/checkmap/${props.project_id}`;
    axios
      .get(requestUrl, {
        headers: { Authorization: `Bearer ${props.token}` },
      })
      .then((response) => {
        logger.log(`vidromapIsPublished`, response);
        resolve(response.data.message);
      })
      .catch((error) => {
        logger.error("vidromapIsPublished error", error);
        resolve(false);
      });
  });
};

const VidromapsService = {
  vidromapIsPublished,
};
export default VidromapsService;
