import React, { useContext, useEffect, useState } from "react";
import MapContext from "../../../Contexts/MapContext.js";
//OL
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";

import RichLogger from "../../../Shared/richlogger";
import Confirm from "../../Confirm";
import marker from "../../../assets/img/marker.png";
import eventEmitter from "../../../Shared/events";

const iconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 16],
    anchorXUnits: "fraction",
    anchorYUnits: "pixels",
    opacity: 1,
    src: marker,
  }),
});
let centerMarker = null;
let iconFeature = null;
const AddPoint = (props) => {
  const logger = new RichLogger("components/Tools/Addpoint");
  const { map, setAddedGeom } = useContext(MapContext);
  const [render, setRender] = useState(false);

  centerMarker = () => {
    iconFeature.getGeometry().setCoordinates(map.getView().getCenter());
  };
  //*****************************************************************/
  //*******************           EFFECTS          ******************/
  //*****************************************************************/
  useEffect(() => {
    iconFeature = new Feature({
      geometry: new Point(map.getView().getCenter()),
    });
    iconFeature.setStyle(iconStyle);
    map.getLayers().forEach((layer) => {
      if (layer.get("name") && layer.get("name") === "draw") {
        layer.getSource().addFeature(iconFeature);
      }
    });

    eventEmitter.on("moveend", () => {
      centerMarker();
    });
    eventEmitter.on("movestart", () => {
      centerMarker();
    });
    eventEmitter.on("pointerdrag", () => {
      centerMarker();
    });
    eventEmitter.on("resolution", () => {
      centerMarker();
    });
    return () => {
      //remove event listeners
      eventEmitter.removeAllListeners();
      iconFeature = null;
    };
  }, []);

  useEffect(() => {
    if (!map) return;
    setRender(true);
  }, [map]);

  useEffect(() => {
    if (!render) {
    }
  }, [render]);

  //*****************************************************************/
  //*******************        END EFFECTS         ******************/
  //*****************************************************************/

  //*****************************************************************/
  //*******************           BUTTONS          ******************/
  //*****************************************************************/

  const cancelAdd = () => {
    logger.log("cancelAdd()");
    props.vectorLayer.current.clear();
    props.cancelAction();
    // setAddedGeom("cancelAdd");
    setRender(false);
  };
  const doAdd = () => {
    logger.log("doAdd()");
    setAddedGeom(iconFeature.getGeometry());
    props.vectorLayer.current.clear();
    setRender(false);
  };
  //*****************************************************************/
  //*******************          END BUTTONS       ******************/
  //*****************************************************************/

  return (
    <React.Fragment>
      {render && <Confirm cancelAdd={cancelAdd} doAdd={doAdd}></Confirm>}
    </React.Fragment>
  );
};
export default AddPoint;
