import RichLogger from "./richlogger";
import WmsService from "./wms-service";
import parser from "xml2json-light";
const logger = new RichLogger("WFSFilters");
const setWFSFlters = (props) => {
  return new Promise((resolve, reject) => {
    WmsService.WFSrequest({
      api_uri: props.api_uri,
      token: props.token,
      project_id: props.project_id,
      name: props.project_name,
      request: `wmts?service=WFS&request=DescribeFeatureType&version=1.0.0&typename=${props.name}&type=${props.type}`,
    }).then((wfsresponse) => {
      //¡ logger.success("setWFSFlters", wfsresponse.data);

      var json = parser.xml2json(wfsresponse.data);
      let dataToIterate = null;
      let retorno = [];
      if (typeof json.schema != "undefined") {
        if (typeof json.schema.complexType != "undefined") {
          if (typeof json.schema.complexType.length != "undefined") {
            for (var l = 0; l < json.schema.complexType.length; l++) {
              if (json.schema.complexType[l].name === `${props.name}Type`) {
                dataToIterate = json.schema.complexType[l];
                break;
              }
            }
          } else {
            dataToIterate = json.schema.complexType;
          }
        }
      }
      if (dataToIterate) {
        if (typeof dataToIterate.complexContent != "undefined") {
          if (typeof dataToIterate.complexContent.extension != "undefined") {
            if (
              typeof dataToIterate.complexContent.extension.sequence !=
              "undefined"
            ) {
              if (
                typeof dataToIterate.complexContent.extension.sequence
                  .element != "undefined"
              ) {
                for (
                  var i = 0;
                  i <
                  dataToIterate.complexContent.extension.sequence.element
                    .length;
                  i++
                ) {
                  retorno.push(
                    dataToIterate.complexContent.extension.sequence.element[i]
                      .name
                  );
                }
              }
            }
          }
        }
      }
      resolve(retorno);
    });
  });
};
const GetWMSFilters = (props) => {
  //_initDate, _endDate;
  logger.log(`getWMSFilters(${props.layer_name})`, "info", {
    filters: props.filters,
    layerAvailableFilters: props.layerAvailableFilters,
  });
  var filter = "";
  if (props.layerAvailableFilters.indexOf("from_date") > -1) {
    logger.log("getWMSFilters applying filter 'from_date'");
    filter +=
      '"from_date"  > \'' +
      props.initDate +
      "' AND \"to_date\" < '" +
      props.endDate +
      "'";
  }
  if (props.layerAvailableFilters.indexOf("username") > -1) {
    logger.log("getWMSFilters applying filter 'username'", "info");
    if (filter !== "") {
      filter += " AND ";
    }
    filter += '"username" = \'' + props.userName + "'";
  }

  /*if (typeof mincut_id != null) {
    if (props.layerAvailableFilters.indexOf("result_id") > -1) {
      logger.log("getWMSFilters applying filter 'result_id'", "info");
      if (filter != "") {
        filter += " AND ";
      }
      filter += '"result_id" = ' + mincut_id;
    }
  }*/

  for (const prop in props.filters) {
    logger.log("getWMSFilters filter iteration", {
      filter: prop,
      value: props.filters[prop],
      layerAvailableFilters: props.layerAvailableFilters,
    });
    if (props.layerAvailableFilters.indexOf(prop) > -1) {
      logger.log(
        `getWMSFilters filter:${prop} is available for layer ${props.layer_name} should apply filter`
      );
      if (filter !== "") {
        filter += " AND ";
      }

      if (props.filters[prop].length > 0) {
        filter += `"${prop}" IN ( ${props.filters[prop].join(" , ")} )`;
      } else {
        filter += `"${prop}" IN ( -1 )`;
      }
    }
  }
  return filter;
};

const WFSFilters = {
  setWFSFlters,
  GetWMSFilters,
};

export default WFSFilters;

/*


	function setInitEndDates(_initDate,_endDate){
		log("setInitEndDates("+_initDate+","+_endDate+")","info");
		var day     = _initDate.getDate();
		var month   = ("0" + (_initDate.getMonth() + 1)).slice(-2);
		var year    = _initDate.getFullYear();
		initDate    = year+"-"+month+"-"+day;
		day     		= _endDate.getDate();
		month   		= ("0" + (_endDate.getMonth() + 1)).slice(-2);
		year    		= _endDate.getFullYear();
		endDate     = year+"-"+month+"-"+day;;
	}

	function getWMSFilters(layer_name,layerAvailableFilters){
		log("getWMSFilters("+layer_name+")","info",{'filters': _filters,'layerAvailableFilters':layerAvailableFilters});
		var filter	= "";
		if(layerAvailableFilters.indexOf("from_date")>-1){
      log("getWMSFilters applying filter 'from_date'","info");
			filter += '\"from_date\"  > \''+initDate+'\' AND \"to_date\" < \''+endDate+'\''
		}
		if(layerAvailableFilters.indexOf("username")>-1){
      log("getWMSFilters applying filter 'username'","info");
			if(filter!=""){
				filter += ' AND ';
			}
			filter += '\"username\" = \''+userName+'\'';
		}

		if(typeof mincut_id!=null){
			if(layerAvailableFilters.indexOf("result_id")>-1){
        log("getWMSFilters applying filter 'result_id'","info");
				if(filter!=""){
					filter += ' AND ';
				}
				filter += '\"result_id\" = '+mincut_id;
			}
		}

		for (const prop in _filters) {
			log("getWMSFilters filter iteration","info",{'filter': prop,'value':_filters[prop],'layerAvailableFilters':layerAvailableFilters});
			if(layerAvailableFilters.indexOf(prop)>-1){
				log("getWMSFilters filter:"+prop+" is available for layer "+layer_name+" should apply filter","info");
				if(filter!=""){
					filter += ' AND ';
				}

				if(_filters[prop].length>0){
					filter += `\"${prop}\" IN ( ${_filters[prop].join(' , ')} )`;
				}else{
					filter += `\"${prop}\" IN ( -1 )`;
				}
			}
		}










//Get from WFS layer available features, used for filters
	function _getFeatureTypeFromWFS(layer_name,cb){
		log("_getFeatureTypeFromWFS("+layer_name+")","info");
		layer_name = layer_name.split("XXX").join(" ");
		var retorno = Array();
		try{
      if(layer_name){
  			layer_name = layer_name.replace(/ /g,"_");
  			var url    = urlWMS+"?service=WFS&request=DescribeFeatureType&version=1.0.0&typename="+layer_name;
  			log("_getFeatureTypeFromWFS url","info",url);
  			$.get(url, function(response, status){
  				var json = xml2json(response);
  				log("_getFeatureTypeFromWFS("+layer_name+")","success",json);
					var dataToIterate = null;
  				if(typeof json.schema!="undefined"){
  					if(typeof json.schema.complexType!="undefined"){
							if(typeof json.schema.complexType.length!="undefined"){
								for(var l=0;l<json.schema.complexType.length;l++){
									if(json.schema.complexType[l].name===`${layer_name}Type`){
										dataToIterate = json.schema.complexType[l];
										break;
									}
								}
							}else{
								dataToIterate = json.schema.complexType;
							}
						}
					}
					if(dataToIterate){
						if(typeof dataToIterate.complexContent!="undefined"){
							if(typeof dataToIterate.complexContent.extension!="undefined"){
								if(typeof dataToIterate.complexContent.extension.sequence!="undefined"){
									if(typeof dataToIterate.complexContent.extension.sequence.element!="undefined"){
										for(var i=0;i<dataToIterate.complexContent.extension.sequence.element.length;i++){
											retorno.push(dataToIterate.complexContent.extension.sequence.element[i].name);
										}
									}
								}
							}
						}
  				}
  				cb(retorno);
  			});
      }else{
        cb(retorno);
      }
		}catch(e){
			log("error in _getFeatureTypeFromWFS("+layer_name+")","warn",e);
			cb(retorno);
		}
	}
*/
