import cookie from "react-cookies";

export const setDevBugCookie = (data) => {
  if (cookie.load("vidromaps") !== undefined) cookie.remove("vidromaps");
  if (data.debug) {
    const expires = new Date();
    expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 7);
    cookie.save("vidromaps", data, {
      path: "/",
      expires: expires,
    });
  }
};

export const getDevBugCookie = () => {
  try {
    if (cookie.load("vidromaps") === undefined) return { debug: false };
    return JSON.parse(cookie.load("vidromaps", true));
  } catch (e) {
    console.error(e);
    return { debug: false };
  }
};
