import devBug from "./devbug";
export default class RichLogger {
  static isEnabled = process.env.REACT_APP_ENV === "dev";

  constructor(source) {
    if (devBug.getShowLog() === 0 || devBug.getShowLog() === false) {
      this.log = this.info = this.success = this.warn = this.error = () => {};
      return;
    }
    this.source = source;
  }

  log(...rest) {
    console.log(`%c${this.source}:`, `font-weight:bold;`, ...rest);
  }

  warn(...rest) {
    console.warn(`%c${this.source}:`, `font-weight:bold;`, ...rest);
  }

  error(...rest) {
    console.error(`%c${this.source}:`, `font-weight:bold;`, ...rest);
  }
  info(...rest) {
    console.info(
      `%c${this.source}:`,
      `font-weight:bold;color:#46b7ff;`,
      ...rest
    );
  }
  success(...rest) {
    let first = rest[0];
    rest.shift();
    console.log(
      `%c${this.source}: ${first}`,
      `color: #07cb03; font-weight:bold;`,
      ...rest
    );
  }
}
