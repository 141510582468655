import axios from "axios";
import RichLogger from "./richlogger";
const getProject = (props) => {
  const logger = new RichLogger("api-service");
  return new Promise((resolve, reject) => {
    let requestUrl = `${props.api_uri}/project/${props.project_id}`;
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        logger.log(`getProject`, response);
        resolve(response.data.message);
      })
      .catch((error) => {
        logger.error("getProject error", error);
        reject(error);
      });
  });
};

const getBackground = (props, bg_id) => {
  return new Promise((resolve, reject) => {
    const logger = new RichLogger("api-service getBackground");
    let requestUrl = `${props.api_uri}/backgrounds/${bg_id}`;
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        logger.log(`getBackground`, response);
        resolve(response.data.message);
      })
      .catch((error) => {
        logger.error("getBackground error", error);
        reject(error);
      });
  });
};

const getLayers = (props) => {
  return new Promise((resolve, reject) => {
    const logger = new RichLogger("api-service getLayers");
    let requestUrl = `${props.api_uri}/layers/${props.project_id}`;
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        logger.log(`getLayers`, response);
        resolve(response.data.message);
      })
      .catch((error) => {
        logger.error("getLayers error", error);
        reject(error);
      });
  });
};
const getTiled = (props) => {
  return new Promise((resolve, reject) => {
    const logger = new RichLogger("api-service getTiled");
    let requestUrl = `${props.api_uri}tiled/${props.selected_tiled}`;
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        logger.log(`getTiled`, response);
        resolve(response.data.message);
      })
      .catch((error) => {
        logger.error("getTiled error", error);
        reject(error);
      });
  });
};
const getFilters = (props) => {
  return new Promise((resolve, reject) => {
    const logger = new RichLogger("api-service getFilters");
    let requestUrl = `${props.api_uri}/giswater/filters/?project_id=${props.project_id}`;
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        logger.log(`getFilters`, response);
        resolve(response.data.message);
      })
      .catch((error) => {
        logger.error("getFilters error", error);
        reject(error);
      });
  });
};
const ApiService = {
  getProject,
  getBackground,
  getLayers,
  getTiled,
  getFilters,
};
export default ApiService;
