import { getDevBugCookie } from "./cookies";

const getShowLog = (what) => {
  const devCookie = getDevBugCookie();
  return devCookie.debug;
};
const devBug = {
  getShowLog,
};

export default devBug;
