import { ImageWMS } from "ol/source";
function imgWmsSource(props) {
  return new ImageWMS({
    url: `${props.api_uri}/wms`,
    params: {
      LAYERS: props.name,
      project_id: props.project_id,
      name: props.project_name,
      token: props.token,
      type: props.type,
      FILTER: props.filters,
      TRANSPARENT: props.transparent,
    },
    imageLoadFunction: (tile, src) => {
      var client = new XMLHttpRequest();
      client.open("GET", src);
      client.responseType = "arraybuffer";
      client.setRequestHeader("Authorization", `Bearer ${props.token}`);
      client.onload = function () {
        const arrayBufferView = new Uint8Array(this.response);
        const blob = new Blob([arrayBufferView], { type: "image/png" });
        const urlCreator = window.URL;
        const imageUrl = urlCreator.createObjectURL(blob);
        tile.getImage().src = imageUrl;
      };
      client.send();
    },
    crossOrigin: "Anonymous",
  });
}
export default imgWmsSource;
