import {
  useContext,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import MapContext from "../../../Contexts/MapContext.js";
import OLVectorLayer from "ol/layer/Vector";
import RichLogger from "../../../Shared/richlogger";
import Features from "../../../Shared/Features";
import Point from "ol/geom/Point";

/*import { Stroke, Circle, Style } from "ol/style";
import * as olEasing from "ol/easing";
import Observable from "ol/Observable";*/
const logger = new RichLogger("components/VectorLayer");
let vectorLayer = null;
const VectorLayer = forwardRef((props, ref) => {
  const { map } = useContext(MapContext);
  const { source, zIndex, projectProperties, customGeoms } = props;
  const [flashFeu, setFlashFeu] = useState(false);
  const remove = () => {
    logger.info("remove");
    map.removeLayer(vectorLayer);
  };

  const clear = () => {
    logger.info("clear");
    vectorLayer.getSource().clear();
  };

  const addFeature = (geom, name, _animate) => {
    logger.info("addFeature", { geom: geom, name: name, animate: _animate });
    setFlashFeu(true);
    let feu = Features.createFeature(geom, name, customGeoms);
    vectorLayer.getSource().addFeature(feu);
    vectorLayer.setZIndex(999);
  };

  const highlightCoordinates = (Coordinates) => {
    logger.info("highlightCoordinates", Coordinates);
    addFeature(new Point(Coordinates), "highlight", false);
  };
  useImperativeHandle(ref, () => ({
    remove: remove,
    clear: clear,
    addFeature: addFeature,
    highlightCoordinates: highlightCoordinates,
  }));
  /*const flash = (feature) => {
    console.log(flashFeu);
    //if (flashFeu) {
    let duration = 3000; //animation duration
    var start = new Date().getTime();
    var listenerKey;
    function animate(event) {
      console.log(event);
      var vectorContext = event.vectorContext;
      var frameState = event.frameState;
      var flashGeom = feature.getGeometry().clone();
      var elapsed = frameState.time - start;
      var elapsedRatio = elapsed / duration;
      // radius will be 5 at start and 30 at end.
      var radius = olEasing.easeOut(elapsedRatio) * 25 + 5;
      var opacity = olEasing.easeOut(1 - elapsedRatio);
      var flashStyle = new Style({
        image: new Circle({
          radius: radius,
          snapToPixel: false,
          stroke: new Stroke({
            color: "rgba(255, 0, 0, " + opacity + ")",
            width: 1,
            opacity: opacity,
          }),
        }),
      });

      vectorContext.setStyle(flashStyle);
      vectorContext.drawGeometry(flashGeom, null);
      if (elapsed > duration) {
        Observable.unByKey(listenerKey);
        return;
      }
      // tell OL3 to continue postcompose animation
      frameState.animate = true;
    }
    listenerKey = map.on("postcompose", animate);
    //}
  };*/
  useEffect(() => {
    if (!map) return;
    if (vectorLayer) return;
    vectorLayer = new OLVectorLayer({
      updateWhileInteracting: true,
      source,
      name: props.name,
    });
    map.addLayer(vectorLayer);
    vectorLayer.setZIndex(zIndex);
    //listener for animate points
    /*vectorLayer.getSource().on("addfeature", function (e) {
      //console.log(e);
      //	if(animateFeature){
      //flash(e.feature);
      //	}
    });*/
    return () => {
      // logger.log("loaded", map);
      if (map) {
        map.removeLayer(vectorLayer);
      }
    };
  }, [map]);
  return null;
});
export default VectorLayer;
/*
	//animate new added features
	function flash(feature) {
		var start         	= new Date().getTime();
		var listenerKey;
		function animate(event) {
			var vectorContext   = event.vectorContext;
			var frameState     	= event.frameState;
			var flashGeom     	= feature.getGeometry().clone();
			var elapsed     		= frameState.time - start;
			var elapsedRatio   	= elapsed / duration;
			// radius will be 5 at start and 30 at end.
			var radius       		= ol.easing.easeOut(elapsedRatio) * 25 + 5;
			var opacity     		= ol.easing.easeOut(1 - elapsedRatio);
			var flashStyle     	= new ol.style.Style({
															image: new ol.style.Circle({
															radius: radius,
															snapToPixel: false,
															stroke: new ol.style.Stroke({
																color: 'rgba(255, 0, 0, ' + opacity + ')',
																width: 1,
																opacity: opacity
															})
														})
													});

			vectorContext.setStyle(flashStyle);
			vectorContext.drawGeometry(flashGeom, null);
			if (elapsed > duration) {
				ol.Observable.unByKey(listenerKey);
				return;
			}
			// tell OL3 to continue postcompose animation
			frameState.animate = true;

		}
		listenerKey 				= map.on('postcompose', animate);
	}*/
