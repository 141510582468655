import Logger from "./richlogger";

const logger = new Logger("iframe-communicator");
let domId;
class IframeCommunicator {
  constructor(data) {
    this.sessionToken = data.sessionToken;
    domId = "map-frame";
    if (data.domId !== undefined) domId = data.domId;
    this.url = this.getUrl();
  }

  getUrl() {
    const { protocol, hostname, port } = window.location;
    return `${protocol}//${hostname}${port ? ":" + port : ""}`;
  }

  static sendMessageToMap = (message) => {
    // logger.log("sendMessageToVideo", message);
    window.top.frames[this.domId].postMessage(message, this.url);
  };

  static sendMessageToParent = (message) => {
    message.domId = domId;
    // logger.log("sendMessageToParent", message);
    window.top.postMessage(message, "*"); // ToDo: Check if this target is OK.
  };

  onMessageReceived = (event, callback) => {
    // console.log(event.origin , this.url)
    if (event.origin === this.url) {
      // logger.log("onMessageReceived, valid origin");
    } else if (event.data.sessionToken === event.data.sessionToken) {
      // logger.log("onMessageReceived, valid identifier");*/
    } else {
      logger.error("onMessageReceived, invalid origin or identifier");
      return;
    }
    callback(event);
  };
}

export default IframeCommunicator;
export const sendMessageToMap = IframeCommunicator.sendMessageToMap;
export const sendMessageToParent = IframeCommunicator.sendMessageToParent;
