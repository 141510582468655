import axios from "axios";
import RichLogger from "../richlogger";
const infoFromCoordinates = (props, x, y) => {
  const logger = new RichLogger("giswater-service");
  return new Promise((resolve, reject) => {
    let requestUrl = `${props.api_uri}/info/coordinates/`;
    let params = {
      project_id: parseInt(props.project_id),
      x: x,
      y: y,
      active_layer: props.active_layer,
      visible_layers: props.visible_layers,
      srid: props.srid,
      zoomlevel: props.zoomlevel,
      device: props.device,
    };
    axios
      .post(requestUrl, params, {
        headers: { Authorization: `Bearer ${props.token}` },
      })
      .then((response) => {
        logger.log(`infoFromCoordinates`, response);
        resolve(response.data.message);
      })
      .catch((error) => {
        logger.error("infoFromCoordinates error", error);
        reject(error);
      });
  });
};

const GiswaterService = {
  infoFromCoordinates,
};
export default GiswaterService;
