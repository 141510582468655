import "./index.scss";
import ok from "../../assets/img/ok.svg";
import ko from "../../assets/img/ko.svg";
const Confirm = (props) => {
  return (
    <div className="snackbar-buttons">
      {props.doAdd && (
        <img
          className="icon-confirm"
          src={ok}
          alt="OK"
          onClick={props.doAdd}
        ></img>
      )}
      {props.cancelAdd && (
        <img
          className="icon-cancel"
          src={ko}
          alt="KO"
          onClick={props.cancelAdd}
        ></img>
      )}
    </div>
  );
};
export default Confirm;
