// AUTH:
export const SET_AUTH = "SET_AUTH";
export const UNSET_AUTH = "UNSET_AUTH";
export const SET_PROFILE_STATUS = "SET_PROFILE_STATUS";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const SET_PROFILE_CATEGORIES = "SET_PROFILE_CATEGORIES";
export const SET_PROFILE_LANGUAGES = "SET_PROFILE_LANGUAGES";
export const SET_PROFILE_LINKS = "SET_PROFILE_LINKS";
export const SET_PROFILE_PRESENTATION_TMP = "SET_PROFILE_PRESENTATION_TMP";
export const SET_PROFILE_PRESENTATIONS = "SET_PROFILE_PRESENTATIONS";
export const SET_CONDITIONS_CHECKED = "SET_CONDITIONS_CHECKED";
export const SET_TOY_STATUS = "SET_TOY_STATUS";

// ZOOM:
export const INCREMENT_ZOOM = "INCREMENT_ZOOM";
export const SET_ZOOM = "SET_ZOOM";
export const DECREMENT_ZOOM = "DECREMENT_ZOOM";

// DEBUG:
export const SET_DEBUG = "SET_DEBUG";
export const UNSET_DEBUG = "UNSET_DEBUG";
